<template>
  <v-container>
    <v-menu offset-y v-if="items.length" :disabled="disable">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, i) in items" :key="i" @click="addSocial(item)">
          <v-list-item-title>{{item.charAt(0).toUpperCase() + item.slice(1)}}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-row v-for="(value, key) in user.social_networks" :key="key" align="baseline">
      <v-col cols="2" class="text-center">
        <v-btn icon @click="removeSocial(key)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        {{key.charAt(0).toUpperCase() + key.slice(1)}}
      </v-col>
      <v-col>
        <v-text-field outlined dense v-model="user.social_networks[key]" label="URL"></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "SocialNetworks",
  props: ["user","disable"],
  data() {
    return {};
  },
  mounted() {},
  computed: {
    items() {
      return ["web", "facebook", "twitter", "instagram", "pinterest"].filter(
        (x) =>
          !this.user.social_networks ||
          !this.user.social_networks.hasOwnProperty(x)
      );
    },
  },
  methods: {
    addSocial(key) {
      if (!this.user.social_networks) this.user.social_networks = {};
      if (!this.user.social_networks.hasOwnProperty(key))
        this.$set(this.user.social_networks, key, "");
    },
    removeSocial(key) {
      this.$delete(this.user.social_networks, key);
    },
  },
};
</script>